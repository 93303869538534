import type * as React from "react";
import { cn } from "../lib/utils";

export function Wrapper(
  props: React.ComponentPropsWithoutRef<"div">,
): JSX.Element {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn("ml-auto mr-auto max-w-4xl pl-5 pr-5", className)}
      {...rest}
    >
      {children}
    </div>
  );
}
